<template>
  <div>
    <template v-if="inProcess">
      <v-progress-linear height="2" indeterminate/>
    </template>
    <template v-else>
      <template v-if="!docData">
        <div class="title align-self-center justify-self-center text-center" style="width: 100%;">No such customer!</div>
      </template>
      <template v-else>
        <v-row class="align-items-stretch">
          <v-col 
            class="d-flex flex-column" cols="12">
            <card
              class="flex-grow-1"
            >
              <profile :user-data="docData" />
              <div class="pa-4">
                <v-text-field 
                  v-model="email"
                  label="Email"
                />
                <v-btn
                  color="primary"
                >
                  Refresh
                </v-btn>
                <v-btn
                  class="ml-3"
                  color="success"
                  :loading="inSynchronizeProcess"
                  @click="onClickSynchronize"
                >
                  Synchronize
                </v-btn>
              </div>
              <v-col cols="12">
                <v-data-table
                  :loading="!inited"
                  :headers="customersHeaders"
                  :items="customersItems"
                  @click:row="onClickRow"
                ></v-data-table>
              </v-col>
            </card>
          </v-col>
          <v-col cols="6" v-if="false">
            <card
            >
              <timeline
                v-if="eventsLoaded"
                :timeline-data="events"
              />
            </card>
          </v-col>
        </v-row>
      </template>
    </template>
  </div>
</template>
<script>
import Profile from '@/components/profile'

import { getDocsFromCollectionByQuery } from '@/fb'
import Card from '@/components/card'
import Timeline from '@/components/timeline'
import { groupBy } from '@/utils/functions'

export default {
  name: 'DashboardUser',
  components: {
    Profile,
    Timeline,
    Card
  },
  data: () => ({
    email: 'eduard.deinega@beenokle.com',
    inited: false,
    eventsLoaded: false,
    events: [],
    inProcess: false,
    exists: false,
    docData: null,
    inSynchronizeProcess: false,
    customersData: [],
    subscriptionsData: []
  }),
  async created() {
    this.inited = false
    this.init()
  },
  computed: {
    customersItems() {
      return this.customersData
    },
    customersHeaders() {
      return Object.keys(this.customersData[0] || {}).map(key => ({ value: key, text: key }))
    },
    userId() {
      const { userId: result } = this.$route.params
      return result
    }
  },
  methods: {
    async onClickSynchronize() {
      this.inSynchronizeProcess = true
      
      await this.$store.dispatch('synchronizeUser', this.docData)

      this.inSynchronizeProcess  = false
    },
    onClickRow(item) {
      const { CustomerReference } = item
      const path = { path: `/dashboard/user/${this.userId}/customer/${CustomerReference}`}
      console.log(path)
      this.$router.push(path)
    },
    async init() {
      await this.fetchData()


      this.inited = true
    },
    groupByDate(to) {
      const result = groupBy(to, 'createdAt', true)
      return result
    },
    async fetchData() {
      this.inProcess = true

      this.docData = await this.$store.dispatch('getCustomer', this.userId)

      this.inProcess = false

      const [ userId, userData ] = this.docData
      const data = await getDocsFromCollectionByQuery('events', {  
        where: {
          userId: {
            operator: '==',
            operand: userId
          }
        },
        orderBy: {
          createdAt: {
            direction: 'desc'
          }
        }
      })

      this.events = this.groupByDate(data)
      this.eventsLoaded = true

      this.customersData = Object.values(userData.externalData.verifone.customers)
    }
  }
}
</script>